import React, { useState } from 'react';
import { FaHome, FaWallet, FaRocket, FaUserAlt, FaHammer } from 'react-icons/fa';
import {
  Fa,
  Home,
  MinePool,
  Swap,
  Person,
} from '../assets/images/omniNew/bottomBar'
import './ConvexBottomBar.css';
import { useNavigate } from 'react-router-dom';

const ConvexBottomBar = () => {
  const [activeIndex, setActiveIndex] = useState(2);
  const nav = useNavigate()
  const tabs = [
    { icon: <MinePool />, label: '矿池', link: '/minePool' },
    { icon: <Swap />, label: '交易', link: '/trade' },
    { icon: <Home />, label: '首页', link: '/home' },
    { icon: <Fa />, label: '发射台', link: '/launchPad' },
    { icon: <Person />, label: '我的', link: '/myAssets' }
  ];

  return (
    <div className="convex-bottom-bar">
      <div className='convex-bottom-bar-content'>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-item ${index === activeIndex && index !== 2 ? 'active' : ''}  ${index === 2 ? 'actived' : ''}`}
            onClick={() => {
              setActiveIndex(index)
              nav(tab.link)
            }}
          >
            {/* <div class="menu__border"></div> */}
            <div className="icon">{tab.icon}</div>

            {index !== 2 ? <span className="label">{tab.label}</span> : <div className='pb-8'></div>}
          </div>
        ))}

      </div>
      <div class="svg-container">
        <svg viewBox="0 0 202.9 45.5" >
          <clipPath id="menu" clipPathUnits="objectBoundingBox" transform="scale(0.0049285362247413 0.021978021978022)">
            <path d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
          c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
          c9.2,3.6,17.6,4.2,23.3,4H6.7z"/>
          </clipPath>
        </svg>
      </div>
    </div>
  );
};

export default ConvexBottomBar;