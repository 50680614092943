import React from 'react';

const Online = (props) => {
	return (
		<svg height="30" width="28" fill="none" version="1.1" viewBox="0 0 28 30" xmlns="http://www.w3.org/2000/svg">
			<g>
				<g>
					<path style={{ "mixBlendMode": "passthrough" }} d="M2.11178,30C1.496115,30,1,29.4924,1,28.8625C1,26.3919,3.85117,24.6062,8.866109999999999,23.8907L8.866109999999999,23.5482C5.46503,20.8818,5.46503,15.9406,5.46503,12.931750000000001C5.46503,7.966,8.32218,5,13.104,5L13.361,5C18.1429,5,21,7.966,21,12.931750000000001C21,13.29868,20.994,13.66561,20.988,14.04476C20.9701,14.67466,20.468,15.17,19.8464,15.1517C19.2307,15.1333,18.7466,14.6135,18.7645,13.98361C18.7765,13.62891,18.7765,13.28033,18.7765,12.931750000000001C18.7765,9.17686,16.953400000000002,7.2749500000000005,13.361,7.2749500000000005L13.104,7.2749500000000005C9.51166,7.2749500000000005,7.68859,9.17686,7.68859,12.931750000000001C7.68859,16.5643,7.90975,20.264200000000002,10.57561,22.001C10.89839,22.2089,11.0897,22.5697,11.0897,22.9611L11.0897,24.8997C11.0897,25.4807,10.6593,25.9699,10.09743,26.0311C5.6324,26.5142,3.22355,27.8535,3.22355,28.8625C3.22355,29.4863,2.72146,30,2.11178,30Z" fill="#FFFFFF" fillOpacity="1" />
				</g>
				<g>
					<path style={{ "mixBlendMode": "passthrough" }} d="M24.90398,30C24.293799999999997,30,23.80209,29.50374,23.80209,28.88789C23.80209,27.89536,21.414659999999998,26.591929999999998,16.989332,26.11958C16.426539,26.05979,16,25.58147,16,25.01345L16,23.11211C16,22.496264,16.491704,22,17.10189,22C17.71207,22,18.203780000000002,22.496264,18.203780000000002,23.11211L18.203780000000002,24.02691C23.174129999999998,24.72048,25.99995,26.47235,25.99995,28.887900000000002C26.0059,29.49776,25.51417,30,24.90398,30Z" fill="#FFFFFF" fillOpacity="1" />
				</g>
				<g>
					<path style={{ "mixBlendMode": "passthrough" }} d="M16.76839,20C15.11393,20,13.879547,19.82586,13.847233,19.81916C13.25266,19.63163,12.89721,19.00875,13.0264655,18.37248C13.149258,17.7429,13.717978,17.32764,14.3384,17.38792C17.33711,17.7295,21.91273,17.43481,22.714109999999998,15.706818C22.998469999999998,15.0906354,23.7158,14.822731,24.3169,15.124124C24.9179,15.41882,25.17,16.16896,24.8791,16.78514C23.6254,19.49098,19.59261,20,16.76839,20Z" fill="#FFFFFF" fillOpacity="1" />
				</g>
				<g>
					<path style={{ "mixBlendMode": "passthrough" }} d="M26.8802,19C26.2602,19,25.7605,18.5016,25.7605,17.8831L25.7605,13.9798L24.2614,13.4453C23.816,13.2892,23.5149,12.8628,23.5149,12.3944C23.5149,6.79172,18.9938,2.22788,13.4371,2.22788C7.88046,2.22788,3.35928,6.79172,3.35928,12.3944C3.35928,12.7547,3.18469,13.091,2.8897,13.3012L2.23952,13.7636L2.23952,17.8831C2.23952,18.5016,1.73984,19,1.11976,19C0.499677,19,0,18.5016,0,17.8831L0,13.1931C0,12.8328,0.174586,12.4965,0.469576,12.2863L1.1318,11.8119C1.43883,5.24842,6.83896,0,13.4371,0C19.963,0,25.321,5.13432,25.7304,11.6018L27.2535,12.1422C27.699,12.2984,28,12.7247,28,13.1931L28,17.8831C28,18.5016,27.5003,19,26.8802,19Z" fill="#FFFFFF" fillOpacity="1" />
				</g>
			</g>
		</svg>
	)
}

export default Online
