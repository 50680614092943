import React from 'react';

const Home = ({ width = 26, height = 26, color = 'currentColor', ...props }) => {
	return (
		<svg
			width={width} // Controls width based on size prop
			height={height} // Controls height based on size prop
			viewBox="0 0 26 26" // Maintains aspect ratio
			xmlns="http://www.w3.org/2000/svg"
			fill={color} // Uses provided color or defaults to currentColor
			aria-label="Home Icon" // Accessibility label
			role="img" // Defines it as an image for screen readers
			{...props} // Passes additional props, like className or onClick
		>
			<g>
				<path d="M25.178,11.2453L15.3102,0.985837C14.0863,-0.290242,12.0595,-0.333127,10.7827,0.890042C10.7503,0.920791,10.7184,0.952722,10.6868,0.985837L0.821428,11.2453C0.117379,11.8063,-0.169319,12.7422,0.0997699,13.6011C0.39212,14.2635,1.06912,14.671,1.79153,14.6194L3.23957,14.6194L3.23957,22.5159C3.15064,24.335,4.5409,25.8876,6.35927,26L10.5579,26L10.5579,20.004C10.5579,19.1087,10.4242,18.6096,11.3387,18.6096L14.6583,18.6096C15.5752,18.6096,15.4391,19.1087,15.4391,20.004L15.4391,25.9917L19.6413,25.9917C21.4603,25.88,22.8508,24.326,22.7598,22.5065L22.7598,14.6194L24.2055,14.6194C24.9279,14.671,25.6049,14.2635,25.8973,13.6011C26.1715,12.7427,25.885,11.8043,25.178,11.2453L25.178,11.2453Z" />
			</g>
		</svg>
	);
}

export default Home;