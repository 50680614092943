import React from 'react';

const Mine = (props) => {
	return (
		<svg height="23" width="23" fill="none" version="1.1" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path style={{ "mixBlendMode": "passthrough" }} d="M22.6511,8.10278L14.88,0.339052C14.362,-0.130753,13.5659,-0.109872,13.0733,0.38644C12.5807,0.882751,12.5661,1.67868,13.0402,2.19269L15.519,4.66943L12.4775,7.70759L10.9568,6.18851C9.65012,4.88442,7.53377,4.88442,6.22713,6.18851L2.25893,10.1091C0.32528,12.0019,-0.441832,14.7873,0.250216,17.4027C0.942265,20.0181,2.98674,22.0602,5.60377,22.7499C8.22132,23.4415,11.0088,22.6751,12.9042,20.7426L16.7961,16.8074C18.0809,15.5062,18.0809,13.4145,16.7961,12.1132L15.2753,10.5931L18.3169,7.55495L20.7957,10.0317C21.3131,10.5105,22.1165,10.4951,22.6153,9.99689C23.114,9.49864,23.1298,8.69555,22.6511,8.17806L22.6511,8.10278ZM10.3941,13.9993L6.45619,17.9345C6.06758,18.3059,5.45334,18.2992,5.073,17.9193C4.69266,17.5394,4.68544,16.9254,5.05675,16.5367L8.97998,12.6015C9.36858,12.23,9.98282,12.2368,10.3632,12.6167C10.7435,12.9966,10.7507,13.6106,10.3794,13.9993L10.3941,13.9993Z" fill="#FFFFFF" fillOpacity="1" />
			</g>
		</svg>
	)
}

export default Mine
