import React from 'react';

const Gift = (props) => {
	return (
		<svg height="18" width="18" fill="none" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
			<g transform="matrix(-1,0,0,1,36,0)">
				<path style={{ "mixBlendMode": "passthrough" }} d="M34.4571,8.74393L34.4571,6.1728L19.54286,6.1728L19.54286,8.74393L34.4571,8.74393ZM34.4571,10.2866L34.4571,18L19.54286,18L19.54286,10.2866L18,10.2866L18,4.63012L22.01297,4.63012C21.21879,3.25709,21.59516,1.50566,22.88331,0.580001C24.17147,-0.345656,25.95168,-0.143934,27,1.04648C28.048299999999998,-0.143932,29.8285,-0.345653,31.1167,0.580005C32.4048,1.50566,32.7812,3.25709,31.987000000000002,4.63012L36,4.63012L36,10.2866L34.4571,10.2866ZM32.9143,10.2866L21.08571,10.2866L21.08571,16.4573L32.9143,16.4573L32.9143,10.2866ZM29.3143,4.63012C30.1664,4.63012,30.857100000000003,3.93944,30.857100000000003,3.08744C30.857100000000003,2.23544,30.1664,1.54476,29.3143,1.54476C28.4622,1.54476,27.771430000000002,2.23544,27.771430000000002,3.08744L27.771430000000002,4.63012L29.3143,4.63012ZM26.228569999999998,4.63012L26.228569999999998,3.08744C26.228569999999998,2.23544,25.53781,1.54476,24.68571,1.54476C23.83362,1.54476,23.14286,2.23544,23.14286,3.08744C23.14286,3.93944,23.83362,4.63012,24.68571,4.63012L26.228569999999998,4.63012Z" fill="#FFFFFF" fillOpacity="1" />
			</g>
		</svg>
	)
}

export default Gift
