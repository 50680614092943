import React from 'react';

const Person = ({ width = 26, height = 26, color = 'currentColor', ...props }) => {
	return (
		<svg width={width} // Controls width based on size prop
			height={height}
			fill={color} version="1.1" viewBox="0 0 23.9989013671875 24.004058837890625" xmlns="http://www.w3.org/2000/svg">
			<g>
				<g>
					<path d="M10.9918525390625,14.6830564453125C14.2038525390625,14.6830564453125,16.8108025390625,11.7130764453125,16.8108025390625,8.0500764453125C16.897402539062497,5.9135364453125,15.8062025390625,3.9013864453125002,13.9682625390625,2.8085024453125C12.1303625390625,1.7156234453125,9.8413625390625,1.7177874453125,8.0055225390625,2.8141384453125C6.1696825390625,3.9104864453125,5.0822693390625,5.924696445312501,5.1728511590625,8.0610664453125C5.1728511590625,11.7130764453125,7.7688525390625,14.6830564453125,10.9918525390625,14.6830564453125Z" fill={color} fillOpacity="1" />
				</g>
				<g>
					<path d="M21.9809,22.6030166015625C21.8975,21.0365766015625,21.2257,19.5593866015625,20.0999,18.4670166015625C18.2079,16.5420166015625,15.1389,15.5670166015625,10.9919,15.5670166015625C6.56989,15.5670166015625,3.37989,16.6670166015625,1.54289,18.8450166015625C0.628706,19.8895566015625,0.0904468,21.2100366015625,0.0138941,22.5960166015625L0.0138941,22.6070166015625C-0.0382732,22.9456466015625,0.0579198,23.2903366015625,0.277894,23.5530166015625C0.526102,23.8408366015625,0.887833,24.0056266015625,1.26789,24.004016601562498L20.7599,24.004016601562498C21.1237,24.0066866015625,21.4716,23.8547466015625,21.7169,23.5860166015625C21.9297,23.3068566015625,22.0245,22.9553366015625,21.9809,22.6070166015625L21.9809,22.6030166015625Z" fill={color} fillOpacity="1" />
				</g>
				<g>
					<ellipse cx="21.4989013671875" cy="2.5" fill="#E7166B" fillOpacity="1" rx="2.5" ry="2.5" />
				</g>
			</g>
		</svg>
	)
}

export default Person
