import React from 'react';

const Nft = (props) => {
	return (
		<svg height="25.34136962890625" width="27.9998779296875" fill="none" version="1.1" viewBox="0 0 27.9998779296875 25.34136962890625" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path style={{ "mixBlendMode": "passthrough" }} d="M14.6399,0.043075L25.6828,1.9902C27.2076,2.25904,28.2256,3.71301,27.9568,5.23773L25.036,21.8022C24.7672,23.327,23.3132,24.3451,21.7885,24.0762L10.7454,22.129C9.2207,21.8602,8.20264,20.4062,8.47154,18.8814L11.3923,2.31695C11.6612,0.792231,13.1151,-0.225831,14.6399,0.043075ZM9.71039,3.43831L6.78967,20.0028C6.52083,21.5275,7.53883,22.9815,9.06355,23.2503L13.4447,24.0228L6.21136,25.2983C4.68661,25.5672,3.23267,24.5491,2.96383,23.0244L0.0430454,6.45984C-0.225767,4.93509,0.792358,3.48115,2.31708,3.21231L10.7501,1.72537C10.2238,2.13684,9.83558,2.72872,9.71039,3.43831ZM22.7811,17.4242L22.7811,17.4226Q22.8958,15.6702,21.7683,14.8143C21.0539,14.273,21.1077,14.6345,20.2163,14.052Q19.3256,13.4705,19.1212,13.296L19.2789,12.3497Q19.6896,12.1303,19.9603,11.2565C20.1732,11.364,20.3093,11.037,20.3604,10.8225C20.3683,10.7899,20.3807,10.7468,20.3949,10.6973C20.4715,10.4317,20.6006,9.98324,20.3594,9.99167C20.493,9.56486,20.6072,9.17804,20.6239,8.96533C20.6845,8.21967,20.3281,7.36661,19.0979,7.09217C18.0334,6.96232,17.2145,7.60801,17.0164,8.32957C16.9593,8.53517,16.931,8.93692,16.9106,9.38404C16.688,9.29368,16.6558,9.75494,16.6365,10.0309C16.6328,10.0839,16.6296,10.13,16.6256,10.1641C16.6002,10.3839,16.618,10.7384,16.8565,10.7104Q16.8105,11.6239,17.1283,11.9721L16.9666,12.9201Q16.7423,13.0239,15.7065,13.2657C15.1943,13.3859,15.0372,13.3798,14.9062,13.3747C14.772,13.3695,14.6652,13.3653,14.2318,13.4991Q12.8795,13.9178,12.6874,15.6702L12.6874,15.6718C12.6874,15.937,12.7915,16.1895,13.0487,16.2349L22.036,17.823C22.2931,17.8684,22.7811,17.6895,22.7811,17.4242Z" fill="#FFFFFF" fillOpacity="1" fillRule="evenodd" />
			</g>
		</svg>
	)
}

export default Nft
