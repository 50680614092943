import React from 'react';

const Emission = (props) => {
	return (
		<svg height="21" width="21" fill="none" version="1.1" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
			<g>
				<g>
					<path style={{ "mixBlendMode": "passthrough" }} d="M6.61553,16.37149C6.30783,16.25673,6.00013,16.44799,5.88474,16.71575C5.07703,19.04914,2.42313,19.62292,1.19233,19.77593C1.34618,18.551859999999998,1.92312,15.91246,4.26932,15.10916C4.57702,14.994406,4.73087,14.688388,4.61548,14.382371C4.5001,14.0763535,4.1924,13.9233444,3.8847,14.038101C0.0384627,15.33868,0,20.1967,0,20.42622C0,20.73224,0.269237,21,0.576936,21C0.769247,21,5.69243,20.961750000000002,6.96169,17.13653C7.07708,16.79226,6.92323,16.48624,6.61553,16.37149Z" fill="#FFFFFF" fillOpacity="1" />
				</g>
				<g>
					<path style={{ "mixBlendMode": "passthrough" }} d="M20.9338,1.29863C20.8545,0.664376,20.3386,0.149049,19.7036,0.0697675C18.2353,-0.128436,15.3779,-0.0887948,12.5206,2.76533L11.4888,3.79598L4.86141,3.79598C4.46456,3.79598,4.1074,3.95455,3.86929,4.23203L0.416693,7.68076C-0.138897,8.23573,-0.138897,9.14746,0.416693,9.74207C0.615118,9.94028,0.892913,10.0988,1.17071,10.1385L5.417,10.9709L9.98078,15.5296L10.8539,19.8108C10.8935,20.0883,11.0523,20.3658,11.2507,20.564C11.5285,20.8414,11.8857,21,12.2825,21C12.6397,21,13.0365,20.8414,13.3143,20.564L16.7669,17.1152C17.0447,16.8377,17.2035,16.481,17.2035,16.0846L17.2035,9.46459L18.2353,8.43393C21.0529,5.57981,21.1323,2.72569,20.9338,1.29863ZM5.53606,9.78171L1.36913,8.94926C1.32945,8.94926,1.28976,8.90962,1.25008,8.86998C1.13102,8.75106,1.13102,8.59249,1.25008,8.51321L4.70267,5.06448C4.74236,5.02484,4.82173,4.9852,4.86141,4.9852L10.2983,4.9852L5.53606,9.78171ZM15.9732,16.0846C15.9732,16.1639,15.9335,16.2035,15.8938,16.2828L12.4412,19.7315C12.3222,19.8504,12.1635,19.8504,12.0841,19.7315C12.0444,19.6919,12.0047,19.6522,12.0047,19.6126L11.1713,15.4503L15.9732,10.6538L15.9732,16.0846ZM16.6082,6.13478Q16.5685,6.13478,16.6082,6.13478C16.5685,6.13478,16.5685,6.13478,16.6082,6.13478C15.497,6.13478,14.9017,5.26269,14.9017,4.39059C14.9017,3.5185,15.497,2.68605,16.6082,2.64641C17.7194,2.64641,18.3146,3.5185,18.3146,4.39059C18.3146,5.26269,17.7194,6.09514,16.6082,6.13478Z" fill="#FFFFFF" fillOpacity="1" />
				</g>
			</g>
		</svg>
	)
}

export default Emission
