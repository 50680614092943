import React from 'react';

const MinePool = ({ width = 26, height = 26, color = 'currentColor', ...props }) => {
	return (
		<svg
			width={width} // Controls width based on size prop
			height={height}
			fill={color}
			version="1.1"
			viewBox="0 0 26.57164764404297 26.582700729370117" xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g>
				<path style={{ "mixBlendMode": "passthrough" }} d="M26.1686,9.36495L17.1907,0.391866C16.5923,-0.151121,15.6725,-0.126986,15.1034,0.446636C14.5343,1.02026,14.5175,1.94017,15.0652,2.53424L17.929,5.39678L14.4151,8.9082L12.6582,7.15249C11.1487,5.64527,8.70368,5.64527,7.19413,7.15249L2.60972,11.6837C0.375792,13.8715,-0.510444,17.0907,0.289072,20.1136C1.08859,23.1364,3.45055,25.4965,6.47397,26.2937C9.498,27.093,12.7183,26.2071,14.9081,23.9737L19.4043,19.4255C20.8886,17.9216,20.8886,15.504,19.4043,14.0001L17.6474,12.2432L21.1613,8.73178L24.025,11.5943C24.6228,12.1477,25.551,12.13,26.1272,11.5541C26.7034,10.9782,26.7216,10.05,26.1686,9.45195L26.1686,9.36495ZM12.0081,16.1799L7.45876,20.7281C7.00981,21.1574,6.30018,21.1496,5.86078,20.7105C5.42138,20.2715,5.41304,19.5618,5.84201,19.1126L10.3745,14.5644C10.8234,14.1351,11.533,14.1429,11.9724,14.582C12.4118,15.0211,12.4202,15.7307,11.9912,16.1799L12.0081,16.1799Z" fill={color} fillOpacity="1" />
			</g>
		</svg>
	)
}

export default MinePool
