import React from 'react';

const Fa = ({ width = 25, height = 25, ...props }) => {
	return (
		<svg
			width={width}
			height={height}
			fill="currentColor"
			viewBox="0 0 25 25"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g>
				<g>
					<path
						style={{ mixBlendMode: "passthrough" }}
						d="M7.87563,19.48986603088379C7.50932,19.35324603088379,7.14301,19.58093603088379,7.00565,19.899706030883788C6.04409,22.67754603088379,2.88468,23.36061603088379,1.41944,23.54277603088379C1.6026,22.08554603088379,2.28943,18.94340603088379,5.08253,17.98709603088379C5.44884,17.85048603088379,5.63199,17.48617603088379,5.49462,17.12186903088379C5.35726,16.757563030883787,4.99095,16.575409430883788,4.62464,16.71202443088379C0.0457889,18.26032603088379,0,24.04369603088379,0,24.31692603088379C0,24.681236030883788,0.32052,24.99999603088379,0.686828,24.99999603088379C0.915771,24.99999603088379,6.7767,24.95446603088379,8.28773,20.40062603088379C8.42509,19.99078603088379,8.24194,19.62647603088379,7.87563,19.48986603088379Z"
					/>
				</g>
				<g>
					<path
						style={{ mixBlendMode: "passthrough" }}
						d="M24.9212,1.54598C24.8268,0.790924,24.2126,0.177439,23.4567,0.0830565C21.7086,-0.152899,18.3071,-0.105708,14.9055,3.29206L13.6772,4.51903L5.7874,4.51903C5.31496,4.51903,4.88976,4.70779,4.6063,5.03813L0.496063,9.14376C-0.165354,9.80444,-0.165354,10.8898,0.496063,11.5977C0.732283,11.8337,1.06299,12.0224,1.3937,12.0696L6.44881,13.0606L11.8819,18.4876L12.9213,23.5843C12.9685,23.9146,13.1575,24.2449,13.3937,24.4809C13.7244,24.8112,14.1496,25,14.622,25C15.0472,25,15.5197,24.8112,15.8504,24.4809L19.9606,20.3753C20.2913,20.0449,20.4803,19.6202,20.4803,19.1483L20.4803,11.2674L21.7086,10.0404C25.063,6.64263,25.1575,3.24487,24.9212,1.54598ZM6.59055,11.6449L1.62992,10.6539C1.58268,10.6539,1.53543,10.6067,1.48819,10.5595C1.34646,10.4179,1.34646,10.2292,1.48819,10.1348L5.59842,6.02915C5.64566,5.98196,5.74015,5.93476,5.7874,5.93476L12.2598,5.93476L6.59055,11.6449ZM19.0157,19.1483C19.0157,19.2427,18.9685,19.2899,18.9212,19.3843L14.811,23.4899C14.6693,23.6315,14.4803,23.6315,14.3858,23.4899C14.3386,23.4427,14.2913,23.3955,14.2913,23.3483L13.2992,18.3932L19.0157,12.6831L19.0157,19.1483ZM19.7716,7.30331Q19.7244,7.30331,19.7716,7.30331C19.7244,7.30331,19.7244,7.30331,19.7716,7.30331C18.4488,7.30331,17.7401,6.2651,17.7401,5.2269C17.7401,4.18869,18.4488,3.19767,19.7716,3.15048C21.0945,3.15048,21.8031,4.18869,21.8031,5.2269C21.8031,6.2651,21.0945,7.25612,19.7716,7.30331Z"
					/>
				</g>
			</g>
		</svg>
	);
};

export default Fa;