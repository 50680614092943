import React from 'react';

const Swap = (props) => {
	return (
		<svg height="24" width="24" fill="none" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path style={{ "mixBlendMode": "passthrough" }} d="M9.33397,14.001L9.33397,10.0019C9.33397,8.53012,10.5282,7.3359,12,7.3359L21.334,7.3359L21.334,2.66602C21.334,1.19422,20.1398,0,18.6679,0L2.66602,0C1.19422,0,0,1.19422,0,2.66602L0,21.334C0,22.8058,1.19422,24,2.66602,24L18.6651,24C20.1369,24,21.3311,22.8058,21.3311,21.334L21.3311,16.667L12,16.667C10.5282,16.667,9.33397,15.4728,9.33397,14.001ZM22.667,8.66602L12,8.66602C11.2626,8.66602,10.667,9.26169,10.667,9.99903L10.667,13.9981C10.667,14.7354,11.2626,15.3311,12,15.3311L22.667,15.3311C23.4043,15.3311,24,14.7354,24,13.9981L24,10.0019C24,9.26458,23.4043,8.66602,22.667,8.66602ZM14.001,13.333C13.2636,13.333,12.668,12.7373,12.668,12C12.668,11.2626,13.2636,10.667,14.001,10.667C14.7383,10.667,15.334,11.2626,15.334,12C15.334,12.7373,14.7354,13.333,14.001,13.333Z" fill="#FFFFFF" fillOpacity="1" />
			</g>
		</svg>
	)
}

export default Swap
